import { useLocation } from '@reach/router';
import { graphql, PageProps } from 'gatsby';
import queryString from 'query-string';
import React, { ChangeEvent, useState } from 'react';

import { Box, Stack } from '../../components/Core';
import { Flex } from '../../components/Core/Flex';
import Hero from '../../components/Hero';
import { HERO_TYPE } from '../../components/Hero/constants';
import Layout from '../../components/Layout';
import PostsSection from '../../components/PostsSection/PostsSection';
import SEO from '../../components/SEO';
import without from '../../utils/helpers/without';
import { createImageWithArtDirection } from '../../utils/serializeImageForImageComponent';
import Filters from '../components/Blog/Filters';

type IBlogTemplate = 
  PageProps<Queries.PageBlogQuery, IPageContext>;

const Blog: FCWithClassName<IBlogTemplate> = (props) => {
  const { data, pageContext } = props;
  const pageData = data.wpPage;
  const pageTemplateData = 'contenuBlog' in data.wpPage.template ? data.wpPage.template.contenuBlog : null;
  const categories = data.allWpCategory.nodes;
  const posts = data.allWpPost.nodes;
  if (!pageData || !pageContext.defaultLanguage || !pageTemplateData) return null;
  const categorySlugs = categories?.map((category) => category.slug);
  const location = useLocation();
  const categoryUrlParam = queryString.parse(location?.search)
    .category as string;
  const categoryFromUrlParam = categoryUrlParam?.replace('/', '');
  const [displayedCategoriesSlugs, setDisplayedCategoriesSlugs] = useState<
    string[]
  >(categoryFromUrlParam ? [categoryFromUrlParam] : categorySlugs || []);

  const onFilterChange = (
    eventOrValue: ChangeEvent<HTMLInputElement> | string[]
  ) => {
    if (eventOrValue instanceof Array) {
      setDisplayedCategoriesSlugs(eventOrValue);
    } else {
      eventOrValue?.target?.checked
        ? setDisplayedCategoriesSlugs(
            Array.from([...displayedCategoriesSlugs, eventOrValue.target.name])
          )
        : setDisplayedCategoriesSlugs(
            without(displayedCategoriesSlugs, eventOrValue.target.name)
          );
    }
  };
  const filteredPosts =
    displayedCategoriesSlugs.length === 0
      ? posts
      : posts.filter((post) =>
          post?.categories.nodes.some((category) => {
            return displayedCategoriesSlugs.includes(category.slug);
          })
        );
  const heroImageWithArtDirection = createImageWithArtDirection({
    desktopImage: pageData.featuredImage?.node,
    mobileImage: pageData.hero?.imageMobile,
  });
  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={pageData} />
      <Stack className="space-y-gap" justify="center">
        <Hero
          type={HERO_TYPE.STATIC}
          couleurTitre={pageData.hero?.couleurTitre}
          heading={pageData.hero?.titre}
          tagline={pageData.hero?.tagline}
          backgroundImage={heroImageWithArtDirection}
        />
        <Box>
          <Flex direction="column" align="center">
            <Filters
              categories={categories}
              displayedCategoriesSlugs={displayedCategoriesSlugs}
              onFilterChange={onFilterChange}
              title={pageTemplateData.sectionTheme.titreTheme}
              defaultChecked
            />
          </Flex>
          <PostsSection
            currentLanguageCode={pageContext.currentLanguage.code}
            displayedCategoriesSlugs={displayedCategoriesSlugs}
            title={undefined}
            description={undefined}
            posts={filteredPosts}
          />
        </Box>
      </Stack>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query PageBlog($id: String, $currentLanguageSlug: String) {
    wpPage(id: { eq: $id }) {
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      language {
        locale
        slug
      }
      link
      seo {
        ...SEOFragment
      }
      slug
      title
      translations {
        language {
          code
          locale
        }
        uri
      }
      hero {
        couleurTitre
        tagline
        titre
        imageMobile {
          ...MediaItemFragment
          localFile {
            ...MobileHeroImage
          }
        }
      }
      template {
        ...on WpTemplate_PageBlog {
          contenuBlog {
            sectionTheme {
              titreTheme
            }
          }
        }
      }
    }
    allWpCategory(
      filter: {
        slug: { ne: "pathologies" }
        language: { slug: { eq: $currentLanguageSlug } }
      }
    ) {
      nodes {
        id
        name
        slug
      }
    }
    allWpPost(
      filter: {
        language: { slug: { eq: $currentLanguageSlug } }
        categories: { nodes: { elemMatch: { slug: { ne: "pathologies" } } } }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...PostEntryFragment
      }
    }
  }
`;
